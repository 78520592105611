<template>
	<div>
    	<shutter-panel ref="shutterPanel" />
	</div>
</template>

<script type="text/javascript">
import Shutter from "@/mixins/Shutter.js"
import ShutterGyneco from "@/mixins/shutters-manager/Gyneco.js"

export default {
	name: 'StatSaillieStallion',
	mixins: [Shutter, ShutterGyneco],
	props: ['horse'],
	data () {
		return {
		}
	},

	mounted() {
		this.init_component()
	},

	methods: {
		async init_component() {
			this.setupStatSaillies(this.horse.horse_id)
		},
	},

	components: {
		
	}
}

</script>